import request from "@/plugins/axios";
export const apiOrderConfirm = (params: any) =>
    request.post("/order.order/confirm", params);
export const apiCustomerLists = (params: any) =>
    request.get("/integral.order/customer", { params });
export const apiShopLists = (params: any) =>
    request.get("/integral.order/shop", { params });

// 关怀列表
export const getCareList = (params: any): Promise<any> =>
    request.get('/care.care/lists', {params})

export const getCareInfo = (params: any): Promise<any> =>
    request.get('/care.care/info', {params})

export const createCareInfo = (params: any): Promise<any> =>
    request.post('/care.care/create', params)

export const reSendSms = (params: any): Promise<any> =>
    request.put('/care.care/reSendSms', params)

export const getMemberDetailByCare = (params: any): Promise<any> =>
    request.get('/care.care/getMemberDetailByCare', {params})

export const getTemplateLists = (params: any): Promise<any> =>
    request.get('/care.careTemplate/lists', {params})

export const getTemplateInfo = (params: any): Promise<any> =>
    request.get('/care.careTemplate/info', {params})

export const getFestivalLists = (params: any): Promise<any> =>
    request.get('/care.careFestivalType/lists', {params})

export const getCompanyTemplateLists = (params: any): Promise<any> =>
    request.get('/care.careCompanyTemplate/lists', {params})

export const getCompanyTemplateInfo = (params: any): Promise<any> =>
    request.get('/care.careCompanyTemplate/info', {params})

export const createCompanyTemplate = (params: any): Promise<any> =>
    request.post('/care.careCompanyTemplate/create', params)
export const apiGetStaffs = (params: any): Promise<any> =>
    request.get('/staff.Staff/staffalllists', {params})

export const updateCompanyTemplate = (params: any): Promise<any> =>
    request.put('/care.careCompanyTemplate/update', params)
export const apidownLoadIndex = (params: any) =>
    request.post("/staff.Staff/export", params);
export const apiCreate = (params: any) =>
    request.post("/integral.order/create", params);
export const apiOrderList = (params: any) =>
    request.get("/integral.order/lists", {params});

export const apiOrderActive = (params: any) =>
    request.post("/integral.order/active", params);
export const apiOrderDel = (params: any) =>
    request.post("/integral.order/del", params);
export const apiOrderDetail = (params: any) =>
    request.get("/integral.order/detail", {params});
export const apiSendLogLists = (params: any) =>
    request.get("/integral.sendLog/lists", {params});
export const apiSendLists = (params: any) =>
    request.get("/integral.send/lists", {params});
export const apiSendDelay = (params: any) =>
    request.post("/integral.send/delay", params);
export const apiSendFreeze = (params: any) =>
    request.post("/integral.send/freeze", params);
export const apiSendUnfreeze = (params: any) =>
    request.post("/integral.send/unfreeze", params);
export const apiSendDel = (params: any) =>
    request.post("/integral.send/del", params);
export const apiSendRepush = (params: any) =>
    request.post("/integral.send/repush", params);
