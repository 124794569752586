












































































import { Component, Vue, Prop } from "vue-property-decorator";
import vueQr from "vue-qr";
import {apiSendLogLists} from "@/api/card_order/order";
import {RequestPaging} from "@/utils/util";
@Component({
	components: { vueQr },
})
export default class pointOrderDetail extends Vue {
	pager: RequestPaging = new RequestPaging();
	apiSendLogLists=apiSendLogLists;
	dialogleVisible=false;
	dialogleTitle="";
	status=0;
	loading=false;
	searchObj: any = {
		create_time: "",
		user_name: "",
		user_tel: "",
		type: "",
		page_no:1,
		page_size:20,
		order_id:0,
	};
	form={
		ids:[],
		remark:"",
	}
	created() {
		this.searchObj.order_id=this.$route.query.id
		this.getList();
	}
	// 列表
	getList(page?: number) {
        page && (this.pager.page = page);
		this.pager.request({
			callback: apiSendLogLists,
			params: {
				...this.searchObj,
			},
		});
	}
	// 重置搜索
	reset(): void {
		Object.keys(this.searchObj).map((key) => {
			if(key!='order_id') {
				this.$set(this.searchObj, key, "");
			}
		});
		this.getList(1);
	}

}
